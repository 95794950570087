.container {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  padding: 20px;
  color: #333;
}

h1 {
  margin-bottom: 20px;
  font-size: 1.5em;
}

p.hint {
  color: #666;
  font-style: italic;
  margin-bottom: 25px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
  max-width: 600px;
}

label {
  font-weight: bold;
  padding-bottom: 15px;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: block;
}

.signature-canvas {
  border: 2px solid #444;
}

.signature-container {
  margin-bottom: 20px;
}

.signature-container.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signature-container > canvas {
  touch-action: none;
  border: 2px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  max-width: 790px;
  background-size: cover;
}

.actionButtons {
  display: flex;
  padding: 20px 0;
  width: 100%;
  justify-content: space-between;
}

button {
  display: inline-block;
  padding: 12px 24px;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #444444;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

button.generate {
  flex: 2;
}

button:hover {
  background-color: #e0e0e0;
}

.allegations .linkButton {
  display: block;
  text-align: center;
}

.allegationContent {
  color: #444;
  padding: 25px;
  background-color: #f5f5f5;
  border: 3px dashed #99999940;
  font-size: 0.9em;
}

.signExplanation {
  display: flex;
  margin-bottom: 20px;
}

.signExplanation img {
  width: 150px;
  height: auto;
}

@media only screen and (max-width: 600px) {
  .container {
    font-size: 20px;
    width: 100%;
    padding: 5px 15px;
  }

  h1.title {
    font-size: 1.2em;
    padding: 0;
  }

  label {
    width: 95%;
    padding-bottom: 10px;
    display: block;
  }

  .form {
    width: 100%;
  }

  input {
    width: 95%;
  }

  .signature-container {
    width: 100%;
  }

  .signature-container > canvas {
    touch-action: none;
    max-width: auto !important;
    touch-action: none;
    background-image: none !important;
  }

  .footer {
    width: 100%;
    margin-bottom: 50px;
  }

  .actionButtons {
    flex-direction: column;
  }

  button.generate {
    flex: none;
    width: 100%;
    margin-bottom: 20px;
  }
}
