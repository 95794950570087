.scroll-button {
  opacity: 0;
  transition: all 1.2s;
  visibility: hidden;
}

.scroll-button.visible {
  opacity: 1;
  transition: all 1.2s;
  visibility: visible;
}
